:root {
  --amplify-components-tabs-item-hover-color: #1976d2 !important;
  --amplify-components-button-primary-hover-background-color: #1976d2 !important;
  --amplify-components-tabs-item-active-color: #1976d2 !important;
  --amplify-components-tabs-item-active-border-color: #1976d2 !important;
  --amplify-components-button-primary-background-color: #1976d2 !important;
  --amplify-components-button-hover-background-color: #1976d2 !important;
  --amplify-components-fieldcontrol-focus-border-color: #1976d2 !important;
  --amplify-components-button-link-hover-color: #1976d2 !important;
  --amplify-components-button-link-color: #1976d2 !important;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://unpkg.com/@typehaus/metropolis");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

/* @font-face {
  font-family: Metropolis;
  font-weight: 400;
  font-style: normal;
  src: url("../public/fonts/Metropolis-Regular.woff2") format("woff2"),
    url("../public/fonts/Metropolis-Regular.woff") format("woff");
}

@font-face {
  font-family: Metropolis;
  font-weight: 400;
  font-style: italic;
  src: url("../public/fonts/Metropolis-RegularItalic.woff2") format("woff2"),
    url("../public/fonts/Metropolis-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: Metropolis;
  font-weight: 500;
  font-style: normal;
  src: url("../public/fonts/Metropolis-Medium.woff2") format("woff2"),
    url("../public/fonts/Metropolis-Medium.woff") format("woff");
}

@font-face {
  font-family: Metropolis;
  font-weight: 700;
  font-style: normal;
  src: url("../public/fonts/Metropolis-Bold.woff2") format("woff2"),
    url("../public/fonts/Metropolis-Bold.woff") format("woff");
} */

body {
  font-family: metropolis, sans-serif;
  height: 100vh;
  width: 100%;
  background-color: #000;
}

.main-wrapper {
  max-width: 88% !important;
  width: 100%;
  margin: 0 auto;
}

.bannerBefore::before {

  content: '';
  background: linear-gradient(180deg, #2FA2E2 0%, #7C50F8 90.63%);
  position: absolute;
  top: 0;
  left: 0;
  width: 45%;
  min-height: 536px;
}

@media(max-width:900px) {
  .bannerBefore::before {
    top: 18%;
    left: 0;
    width: 100%;
    min-height: 300px;
  }

  .bannerprofile {
    width: 340px;
    height: 320px;
  }

}

@media (max-width: 768px) {
  .bannerBefore::before {
    min-height: 300px;
    top: 18%;
  }

  .css-1nw3y3l-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 18px;
  }

  .css-1nw3y3l-MuiButtonBase-root-MuiTab-root {

    font-size: 18px;
  }
}

@media (max-width: 600px) {

  .css-1nw3y3l-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 15px;
    padding: 0;
  }

  .css-1nw3y3l-MuiButtonBase-root-MuiTab-root {

    font-size: 15px;
  }
}

.hidden {
  display: none;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
}

.reviews-section {
  scroll-margin-top: 96px;
  /* Adjust this value to match your navbar height */
}

.reviews-section::before {
  content: '';
  display: block;
  height: 96px;
  /* Adjust this value to match your navbar height */
  margin-top: -96px;
  /* Negative value of the height */
  visibility: hidden;
  pointer-events: none;
}